<template>
  <div class="commonWorkLog-container">
    <div v-if="!followRecordList || !followRecordList.length">暂无记录</div>
    <div v-else class="record-container">
      <div
        v-for="(item, index) in followRecordList"
        :key="index"
        class="record-item"
      >
        <div class="item-top">
          <div class="item-icon"></div>
          <span class="item-role"> {{ item.operationName || "--" }} </span>
          <span class="item-date"> {{ item.time || "--" }} </span>
        </div>
        <div v-if="item.type == 'confirmReceipt'">
          <div class="item-content">
            <div class="item-detail">进账信息</div>
            <div class="item-detail">操作： 确认到账</div>
          </div>
        </div>
        <div v-else-if="item.type == 'voidedCollection'">
          <div class="item-content">
            <div class="item-detail">作废记录</div>
            <div class="item-detail">操作： 作废</div>
            <div class="item-detail">
              作废说明：
              <span
                style="display: inline-block;white-space: pre-line;vertical-align: top;"
                >{{ item.value.remark || "--" }}</span
              >
            </div>
          </div>
        </div>
        <div v-else-if="item.type == 'initiateCollection'">
          <div class="item-content">
            <div class="item-detail">发起收款</div>
            <div class="item-detail">实收金额： {{ item.value[1].amount }}</div>
            <div class="item-detail">
              实收对应时间：
              {{ DateTransform(item.value[1].serviceStartTime) }} ~
              {{ DateTransform(item.value[1].serviceEndTime) }} （{{
                item.value[1].effectiveDays || "0天"
              }}
              ）天
            </div>
            <div class="item-detail">通知人： {{ item.value[1].noticeName }}</div>
            <div class="item-detail">
              备注：<span
                style="display: inline-block;white-space: pre-line;vertical-align: top;"
                >{{ item.value[1].remark }}</span
              >
            </div>
            <div
              v-for="(itemFile, key) in item.value[1].fileUrls"
              :key="key"
              class="item-detail"
            >
              <common-picture :fileUrl="itemFile.fileUrl" :fileType="itemFile.fileType" :fileName="itemFile.fileName"></common-picture>
              <!-- <img
                v-if="itemFile.fileType == 'img'"
                @click="() => downLoad(itemFile.fileUrl)"
                class="fileImg"
                :src="itemFile.fileUrl"
                alt=""
              /> -->
              <!-- <div
                v-else
                @click="() => downLoad(itemFile.fileUrl)"
                class="fileNames"
              >
                <img
                  style="margin-right: 8px"
                  src="../../../assets/images/file_icon.svg"
                />{{ itemFile.fileName }}
              </div> -->
            </div>
            <div v-show="item.value.invoiceName">
              <div class="item-detail">开票信息</div>
              <div style="border-left: 4px solid #1890FF;padding-left: 14px">
                <div class="item-detail">
                  发票类型：
                  {{
                    item.value.invoiceType
                      ? item.value.invoiceType == "common"
                        ? "普通"
                        : "专票"
                      : "--"
                  }}
                </div>
                <div class="item-detail">
                  名称： {{ item.value.invoiceName || "--" }}
                </div>
                <div class="item-detail">
                  纳税人识别号：
                  {{ item.value.invoiceTaxpayerIdentificationNumber || "--" }}
                </div>
                <div class="item-detail">
                  地址、电话： {{ item.value.invoicePhone || "--" }}
                </div>
                <div class="item-detail">
                  开户行及账号： {{ item.value.invoiceAccount || "--" }}
                </div>
              </div>
              <div class="item-detail">收件信息</div>
              <div style="border-left: 4px solid #1890FF;padding-left: 14px">
                <div class="item-detail">
                  收件人：{{ item.value.transactionName || "--" }}
                </div>
                <div class="item-detail">
                  收件人联系方式：{{ item.value.transactionPhone || "--" }}
                </div>
                <div class="item-detail">
                  收件人地址：{{ item.value.invoiceAddress || "--" }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="item.type == 'updateFile'">
          <div class="item-content">
            <div class="item-detail">修改收款附件</div>
              <div v-for="(itemFile, key) in item.value[1].fileUrls" :key="key" class="item-detail">
                <common-picture
                  :fileUrl="itemFile.fileUrl"
                  :fileType="itemFile.fileType"
                  :fileName="itemFile.fileName"
                  ></common-picture>

                <!-- <img v-if="itemFile.fileType == 'img'" @click="() => downLoad(itemFile.fileUrl)" class="fileImg" :src="itemFile.fileUrl" alt=""> -->
                <!-- <div v-else @click="() => downLoad(itemFile.fileUrl)" class="fileNames"><img style="margin-right: 8px" src="../../../assets/images/file_icon.svg" />{{itemFile.fileName}}</div> -->
              </div>
            </div>
        </div>
        <div v-else-if="item.type == 'createIncome'">
          <div class="item-content">
            <div class="item-detail">新增出款</div>
            <div class="item-detail">
              出账金额： {{ item.value.amount || "--" }}
            </div>
            <div class="item-detail">
              出款时间： {{ DateTransform(item.value.recordTime) || "--" }}
            </div>
            <div class="item-detail">
              通知人： {{ item.value.noticeName || "--" }}
            </div>
            <div class="item-detail">
              备注：<span
                style="display: inline-block;white-space: pre-line;vertical-align: top;"
                >{{ item.value.remark || "--" }}</span
              >
            </div>
            <div
              v-for="(itemFile, key) in item.value.fileUrls"
              :key="key"
              class="item-detail"
            >
              <common-picture
                  :fileUrl="itemFile.fileUrl"
                  :fileType="itemFile.fileName.split('.')[itemFile.fileName.split('.').length - 1] == 'jpeg'? 'img':
                  (itemFile.fileName.split('.')[itemFile.fileName.split('.').length - 1] == 'jpg'? 'img':
                  (itemFile.fileName.split('.')[itemFile.fileName.split('.').length - 1] == 'gif'? 'img':
                  (itemFile.fileName.split('.')[itemFile.fileName.split('.').length - 1] == 'png'? 'img':
                  (itemFile.fileName.split('.')[itemFile.fileName.split('.').length - 1] == 'bmp'? 'img':'wenjian'))))"
                  :fileName="itemFile.fileName"
              ></common-picture>
              <!-- <img
                v-if="itemFile.fileType == 'img'"
                @click="() => downLoad(itemFile.fileUrl)"
                class="fileImg"
                :src="itemFile.fileUrl"
                alt=""
              />
              <div
                v-else
                @click="() => downLoad(itemFile.fileUrl)"
                class="fileNames"
              >
                <img
                  style="margin-right: 8px"
                  src="../../../assets/images/file_icon.svg"
                />{{ itemFile.fileName }}
              </div> -->
            </div>
            <div class="item-detail">
              收款人： {{ item.value.transactionName || "--" }}
            </div>
            <div class="item-detail">
              收款方式：{{
                item.value.recordType
                  ? item.value.recordType == "bankCard"
                    ? "银行卡"
                    : item.value.recordType == "alipay"
                    ? "支付宝"
                    : item.value.recordType == "wechat"
                    ? "微信"
                    : item.value.recordType == "cash"
                    ? "现金"
                    : "其他"
                  : "--"
              }}
            </div>
            <div class="item-detail">
              收款账户： {{ item.value.transactionAccount || "--" }}
            </div>
            <div class="item-detail">
              联系方式： {{ item.value.transactionPhone || "--" }}
            </div>
          </div>
        </div>
        <div v-else-if="item.type == 'uploadFile'">
          <div
            v-for="(twoItem, twoIndex) in item.value"
            :key="twoIndex"
            class="item-content"
          >
            <div v-if="twoIndex == 0" class="item-detail">
              <div>上传附件：{{ twoItem.fileName }}</div>
              <common-picture
                  :fileUrl="twoItem.fileUrl"
                  :fileType="twoItem.fileName.split('.')[twoItem.fileName.split('.').length - 1] == 'jpeg'? 'img':
                  (twoItem.fileName.split('.')[twoItem.fileName.split('.').length - 1] == 'jpg'? 'img':
                  (twoItem.fileName.split('.')[twoItem.fileName.split('.').length - 1] == 'gif'? 'img':
                  (twoItem.fileName.split('.')[twoItem.fileName.split('.').length - 1] == 'png'? 'img':
                  (twoItem.fileName.split('.')[twoItem.fileName.split('.').length - 1] == 'bmp'? 'img':'wenjian'))))"
                  :fileName="twoItem.fileName"
                  ></common-picture>
              <!-- <div @click="() => downLoad(twoItem.fileUrl)" class="fileNames">
                <img
                  style="margin-right: 8px"
                  src="../../../assets/images/file_icon.svg"
                />{{ twoItem.fileName }}
              </div> -->
              <!-- <img
                v-if="
                  twoItem.fileName.split('.')[twoItem.fileName.split('.').length - 1] == 'jpeg' || 
                  twoItem.fileName.split('.')[twoItem.fileName.split('.').length - 1] == 'jpg' ||
                  twoItem.fileName.split('.')[twoItem.fileName.split('.').length - 1] == 'gif' ||
                  twoItem.fileName.split('.')[twoItem.fileName.split('.').length - 1] == 'png' ||
                  twoItem.fileName.split('.')[twoItem.fileName.split('.').length - 1] == 'bmp'
                "
                @click="() => downLoad(twoItem.fileUrl)"
                class="fileImg"
                :src="twoItem.fileUrl"
                alt=""
              /> -->
            </div>
          </div>
        </div>
        <div v-else-if="item.type == 'deleteFinancialFile'">
          <div class="item-content">
            <div class="item-detail">删除附件</div>
            <div class="item-detail">合同名称：{{ item.value.fileName }}</div>

            <common-picture
                  :fileUrl="item.value.fileUrl"
                  :fileType="item.value.fileName.split('.')[item.value.fileName.split('.').length - 1] == 'jpeg'? 'img':
                  (item.value.fileName.split('.')[item.value.fileName.split('.').length - 1] == 'jpg'? 'img':
                  (item.value.fileName.split('.')[item.value.fileName.split('.').length - 1] == 'gif'? 'img':
                  (item.value.fileName.split('.')[item.value.fileName.split('.').length - 1] == 'png'? 'img':
                  (item.value.fileName.split('.')[item.value.fileName.split('.').length - 1] == 'bmp'? 'img':'wenjian'))))"
                  :fileName="item.value.fileName"
                  ></common-picture>

            <!-- <div @click="() => downLoad(item.value.fileUrl)" class="fileNames">
              <img
                style="margin-right: 8px"
                src="../../../assets/images/file_icon.svg"
              />{{ item.value.fileName }}
            </div> -->
          </div>

          <img
            v-if="
              item.value.fileName.split('.')[
                item.value.fileName.split('.').length - 1
              ] == 'jpeg' &&
                'jpg' &&
                'png' &&
                'gif' &&
                'bmp'
            "
            :src="item.value.fileUrl"
            alt=""
          />
        </div>
        <div v-else>
          <div
            v-for="(twoItem, twoIndex) in item.value"
            :key="twoIndex"
            class="item-content"
          >
            <div v-if="twoIndex == 0" class="item-detail">{{ twoItem }}</div>
            <div v-else>
                            <div v-for="(val, key) in twoItem" :key="key" class="item-detail">
                                <div v-if="key != '跟进文件' && key != '反馈文件' && key != '下载链接' && key != '文件地址'">
                                    {{key}}: <span style="display: inline-block;white-space: pre-line;vertical-align: top;">{{val}}</span>
                                </div>
                                <div v-else-if="item.value[0] == '删除合同' && (key == '下载链接' || key == '文件地址')">
                                    <div @click="() => downLoad(val)" class="fileNames"><img style="margin-right: 8px" src="../../../assets/images/file_icon.svg" />{{item.value[1]['删除合同名称']}}</div>
                                </div>
                                <div v-else-if="item.value[0] == '上传合同' && (key == '下载链接' || key == '文件地址')">
                                    <div @click="() => downLoad(val)" class="fileNames"><img style="margin-right: 8px" src="../../../assets/images/file_icon.svg" />{{item.value[1]['合同名称']}}</div>
                                </div>
                                <div v-else-if="key == '下载链接' || key == '文件地址'">
                                    <div @click="() => downLoad(val)" class="fileNames"><img style="margin-right: 8px" src="../../../assets/images/file_icon.svg" />{{val}}</div>
                                </div>
                                <div v-else-if="key == '跟进文件' || key == '反馈文件'">
                                    <div v-for="(fileItem, fileIndex) in val" :key="fileIndex">
                                        <common-picture :fileUrl="fileItem['下载链接']" :fileType="fileItem['文件类型'] == '图片' ? 'img' : 'file'" :fileName="fileItem['文件名称']"></common-picture>
                                    <!-- <img v-if="fileItem['文件类型'] == '图片'" @click="() => downLoad(fileItem['下载链接'])" class="fileImg" :src="fileItem['下载链接']" alt="">
                                    <div v-else @click="() => downLoad(fileItem['下载链接'])" class="fileNames"><img style="margin-right: 8px" src="../../../assets/images/file_icon.svg" />{{fileItem['文件名称']}}</div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
          </div>
        </div>
      </div>
      <div>
        <br />
        <br />
      </div>
    </div>
  </div>
</template>
<script>
import { DateTransform } from "../../../utils/index.js";
import CommonPicture from "../../../components/common/commonPicture";
export default {
  props: ["followRecordList"],
  name: "commonWorkLog",
  components:{
    CommonPicture
  },
  data() {
    return {
      DateTransform
    };
  },
  methods: {
    downLoad(url) {
      // 下载文件
      window.location.href = url;
    }
  }
};
</script>
<style lang="less" scoped>
.commonWorkLog-container {
  flex: 1;
  overflow: hidden;
  .record-container {
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    .record-item {
      margin-top: 16px;
      border-top: 1px #eaeaeb dashed;
      padding-top: 16px;
      .fileNames {
        color: #1890ff;
        margin-top: 8px;
        word-break: break-all;
        cursor: pointer;
      }
      .fileImg {
        width: 80px;
        margin-top: 8px;
        cursor: pointer;
      }
      .item-content {
        margin-left: 22px;
        .itemBtn {
          display: inline-block;
          height: 32px;
          line-height: 32px;
          padding: 0 14px;
          border: 1px solid #1890ff;
          border-radius: 4px;
          font-size: 14px;
          color: #101010;
          margin: 16px 16px 8px 0;
        }
        .item-detail {
          color: #333;
          margin-top: 8px;
          vertical-align: middle;
          .visitIcon {
            width: 14px;
            margin-left: 8px;
          }
        }
      }
      .item-top {
        padding-right: 16px;
        .item-icon {
          display: inline-block;
          width: 4px;
          height: 4px;
          background-color: #fff;
          border: 4px #1890ff solid;
          border-radius: 50%;
          margin-right: 8px;
        }
        .item-role {
          color: #1890ff;
        }
        .item-date {
          float: right;
          color: #999;
        }
      }
    }
  }
}
</style>
